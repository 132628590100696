// Generic
export const ALL = 'all'

export const Variant = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  ERROR: 'error',
  NEUTRAL: 'neutral',
  BLACK: 'black',
  WHITE: 'white',
} as const
export type VariantType = (typeof Variant)[keyof typeof Variant]

export const Size = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
} as const
export type SizeType = (typeof Size)[keyof typeof Size]

export type SelectOptionType = {
  id: string | number | symbol | undefined
  label: string
  image?: string
  icon?: string
  value: string | number | boolean
}

export const Category = {
  ENERGY: 'energy',
  TELCO: 'telco',
  INSURANCE: 'insurance',
  ELECTRICITY: 'electricity',
  GAS: 'gas',
  MOBILE: 'mobile',
  RC_AUTO: 'rc_auto',
  RENEWABLE: 'renewable',
  SMART_HOME: 'smart_home',
} as const
export type CategoryType = (typeof Category)[keyof typeof Category]

export const Client = {
  ALL,
  BUSINESS: 'business',
  PRIVATE: 'private',
} as const
export type ClientType = (typeof Client)[keyof typeof Client]

export type TechnicalDoc = {
  name: string
  assetUrl: string
}

export type SeoType = {
  title?: string,
  metaDescription?: string,
  keywords?: string[]
  socialAssetUrl?: string
}

export type DetailType = {
  title: string,
  label: string
}

// Banner
export type BannerType = {
  content?: string,
}

export type BaseRateType = {
  id: string;
  slug?: string
  name: string;
  brandId?: string;
  assetUrl?: string;
  fallbackAssetUrl?: string;
  category?: string;
  bonus?: number;
  promo?: string;
  business?: boolean;
  esclusive?: boolean;
  highlighted?: boolean;
  description?: string;
  total?: number;
  technicalDocList?: TechnicalDoc[];
};

// Brand
export type BrandType = {
  id?: string
  name?: string
  categories?: CategoryType[]
  sort?: number
  assetUrl?: string
  externalUrl?: string
  esclusive?: boolean
}

// Disclaimer
export type DisclaimerType = {
  content?: string,
}

// Footer
export type FooterType = {
  x_url?: string,
  facebook_url?: string,
  instagram_url?: string,
  whatsapp_url?: string,
  contacts?: string,
  working_hours?: string,
  content?: string,
  last?: string,
}

// Page
export const PageUrl = {
  HOMEPAGE: 'homepage',
  BLOG: 'blog',
  ABOUT_US: 'chi-siamo',
  CONTACTS: 'contatti',
  HELP: 'assistenza',
  WORK_WITH_US: 'lavora-con-noi',
  PRIVACY_AND_COOKIES: 'privacy-e-cookie-policy',
  TERMS_AND_CONDITIONS: 'termini-e-condizioni',
  HOW_IT_WORKS: 'come-funziona-risparmia-tu',
} as const
export type PageUrlType = (typeof PageUrl)[keyof typeof PageUrl]

export type PageType = {
  title?: string
  subtitle?: string
  assetUrl?: string,
  content?: string,
  seo?: SeoType
}

// Embedded video
export type EmbeddedVideoType = {
  id: string
  title?: string
  assetUrl?: string,
  embeddedUrl?: string
  tags?: string[]
  seo?: SeoType
}

// Blog Post
export type BlogPostType = {
  id: string
  slug?: string
  title?: string
  assetUrl?: string,
  content?: string
  author?: string
  tags?: string[]
  lastUpdated?: string
  boosted?: boolean
  pinned?: boolean
  estimatedReadTime?: string
  seo?: SeoType
}

// Price
export type PriceDetailType = {
  title: string
  value?: number
  bonus?: boolean
  percentage?: number
}

export const PriceVariant = {
  ALL,
  FIXED: 'fisso',
  INDEXED: 'indicizzato',
} as const
export type PriceVariantType = (typeof PriceVariant)[keyof typeof PriceVariant]


// Luce & Gas
export const EnergySubCategory = {
  ALL,
  GAS: 'gas',
  ELECTRICITY: 'electricity',
} as const
export type EnergySubCategoryType = (typeof EnergySubCategory)[keyof typeof EnergySubCategory]

export type EnergyRateResultType = {
  electricity?: {
    price?: number,
    priceDetail?: PriceDetailType[],
  },
  gas?: {
    price?: number,
    priceDetail?: PriceDetailType[],
  }
  total: number
}

export type EnergyRatePriceType = {
  priceF1?: number
  priceF2?: number
  price?: number
  staticRate: number
  priceDetail?: PriceDetailType[],
  total?: number
}

export type EnergyRateType = BaseRateType & {
  priceVariant: PriceVariantType;
  rateVariant: EnergyRateVariantType;
  payment?: string;
  electricity?: EnergyRatePriceType;
  gas?: EnergyRatePriceType;
}

export const EnergyRateVariant = {
  ALL,
  MONO: 'monoraria',
  BI: 'bioraria',
} as const
export type EnergyRateVariantType = (typeof EnergyRateVariant)[keyof typeof EnergyRateVariant]

export type EnergyRateFilter = {
  category: CategoryType
  subCategory: EnergySubCategoryType
  city: string
  supplier: string
  priceType: PriceVariantType
  rateType: EnergyRateVariantType
  consumeKnown: boolean
  consumePartition: number
  consumeInKwh?: number
  consumeInCubicMeters?: number
}

export const HouseHoldAppliance = {
  AC: 'Climatizzatore',
  FRIDGE: 'Frigorifero',
  WASHING_MACHINE: 'Lavatrice',
  DISH_WASHER: 'Lavastoviglie',
  OVEN: 'Forno',
  WATER_HEATER: 'Scaldabagno',
} as const
export type HouseHoldApplianceType = (typeof HouseHoldAppliance)[keyof typeof HouseHoldAppliance]

export const GasUsage = {
  HOT_WATER: 'Acqua calda',
  COOKING: 'Cottura',
  HEATING: 'Riscaldamento',
} as const
export type GasUsageType = (typeof GasUsage)[keyof typeof GasUsage]

// Insurance
export const InsuranceSubCategory = {
  ALL,
  HOME: 'casa',
  PERSONAL: 'persona',
  EXTRA: 'extra',
} as const
export type InsuranceSubCategoryType = (typeof InsuranceSubCategory)[keyof typeof InsuranceSubCategory]

export type InsuranceRateType = BaseRateType & {
  price?: number;
  priceDetail?: PriceDetailType[];
  subCategory?: InsuranceSubCategoryType;
}

// Telco
export const TelcoSubCategory = {
  ALL,
  WIFI: 'wifi',
  PHONE: 'phone',
} as const
export type TelcoSubCategoryType = (typeof TelcoSubCategory)[keyof typeof TelcoSubCategory]

export const TelcoTechnology = {
  ALL,
  ADSL: 'ADSL',
  FIBER: 'Fibra',
  FWA: 'FWA',
  '4G_5G': '4G/5G',
} as const
export type TelcoTechnologyType = (typeof TelcoTechnology)[keyof typeof TelcoTechnology]

export const ActivationCost = {
  ALL,
  INCLUDED: 'Incluso',
  NOT_INCLUDED: "Non incluso",
} as const
export type ActivationCostType = (typeof ActivationCost)[keyof typeof ActivationCost]

export const Modem = {
  ALL,
  INCLUDED: 'Incluso',
  NOT_INCLUDED: "Non incluso",
} as const
export type ModemType = (typeof Modem)[keyof typeof Modem]

export const Voice = {
  ALL,
  INCLUDED: 'Incluse',
  NOT_INCLUDED: "Non incluse",
} as const
export type VoiceType = (typeof Voice)[keyof typeof Voice]

export type TelcoRateType = BaseRateType & {
  modem?: ModemType;
  activationCost?: ActivationCostType;
  voice?: VoiceType;
  telcoTechnology?: TelcoTechnologyType;
  payment?: string;
  price?: number;
  activationPrice?: number;
  modemPrice?: number;
  priceDetail?: PriceDetailType[];
  subCategory?: TelcoSubCategoryType;
}

// Email
export type EmailPayload = {
  subject: string,
  body: string,
  to: string[],
  cc?: string[],
  bcc?: string[],
}

// Lead
export type NewLeadPayload = {
  category?: string,
  email: string;
  phone: string;
  privacy1: boolean;
  privacy2: boolean;
  privacy3: boolean;
  privacy4: boolean;
  rateName: string;
  brandName: string;
  name?: string;
  surname?: string;
  city?: string;
  province?: string;
  leadType?: 'switch' | 'voltura' | 'subentro' | 'nuova attivazione';
}

export type LeadData = {
  type: 'HOT';
  msisdn: string; // cell number
  channel: string; // lead channel
  gclid: string;
  campaign_id: string;
  privacy_1: '0' | '1';
  privacy_2: '0' | '1';
  privacy_3: '0' | '1';
  privacy_4: '0' | '1';
  leadDate: string;
  leadExpire: string;
  data: {
    addres: string; // IP address
    email?: string; // Email
    file?: {
      nome?: string;
      cognome?: string;
      comune?: string;
      provincia?: string;
      motivo_contatto?: string; // 'switch' | 'voltura' | 'subentro' | 'nuova attivita'
      nome_brand?: string;
      nome_offerta?: string;
    }
    google_keyword?: string;
    google_adgroup?: string;
    google_campaignid?: string;
    google_creative?: string;
    google_adposition?: string;
    google_device?: string;
  };
};

export type GenericRateType = EnergyRateType | InsuranceRateType | TelcoRateType